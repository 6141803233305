import * as React from 'react';

/**
 * Пользовательский хук, который преобразует callback в ref, чтобы избежать повторных рендеров при передаче в качестве
 * пропса или избежать повторного выполнения эффектов при передаче в качестве зависимости
 *
 * Имеет реализацию отличную от @react/hooks/useEventCallback
 */

function useCallbackRef<T extends (...args: unknown[]) => unknown>(callback: T | undefined): T {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    callbackRef.current = callback;
  });

  // https://github.com/facebook/react/issues/19240
  return React.useMemo(() => ((...args) => callbackRef.current?.(...args)) as T, []);
}

export { useCallbackRef };

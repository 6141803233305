import * as React from 'react';

import { POINTER_DOWN_OUTSIDE } from './constants';
import { PointerDownOutsideEvent } from './types';
import { handleAndDispatchCustomEvent } from './useFocusOutside';
import { useCallbackRef } from '../../../react-utils-extra/hooks/useCallbackRef';

/**
 * Слушает событие `pointerdown` вне поддерева React. Мы используем `pointerdown` вместо `pointerup`,
 * чтобы имитировать поведение закрытия слоя, присутствующее в ОС.
 * Возвращает свойства, которые нужно передать узлу, для которого мы хотим проверить события вне его.
 */
export function usePointerDownOutside(
  onPointerDownOutside?: (event: PointerDownOutsideEvent) => void,
  ownerDocument: Document = globalThis?.document,
) {
  const handlePointerDownOutside = useCallbackRef(onPointerDownOutside) as EventListener;
  const isPointerInsideReactTreeRef = React.useRef(false);
  const handleClickRef = React.useRef(() => {});

  React.useEffect(() => {
    const handlePointerDown = (event: PointerEvent) => {
      const eventDetail = { originalEvent: event };

      function handleAndDispatchPointerDownOutsideEvent() {
        handleAndDispatchCustomEvent(POINTER_DOWN_OUTSIDE, handlePointerDownOutside, eventDetail, { discrete: true });
      }

      if (event.target && !isPointerInsideReactTreeRef.current) {
        /**
         * На сенсорных устройствах нам нужно дождаться события click, потому что браузеры реализуют
         * задержку ~350 мс между моментом, когда пользователь перестает касаться экрана, и моментом,
         * когда браузер выполняет события. Нам нужно убедиться, что мы не активируем pointer-events
         * в этот промежуток времени, иначе браузер может выполнить события, которые должны были быть предотвращены.
         *
         * Кроме того, это также позволяет нам автоматически обрабатывать отмены, когда событие click
         * не возникает, потому что страница была прокручена/перетаскивалась, долго нажималась и т.д.
         *
         * Вот почему мы также постоянно удаляем предыдущий слушатель, потому что мы не можем быть
         * уверены, что он был вызван, и, следовательно, очищен.
         */
        if (event.pointerType === 'touch') {
          ownerDocument.removeEventListener('click', handleClickRef.current);
          handleClickRef.current = handleAndDispatchPointerDownOutsideEvent;
          ownerDocument.addEventListener('click', handleClickRef.current, { once: true });
        } else {
          handleAndDispatchPointerDownOutsideEvent();
        }
      } else {
        // Нам нужно удалить слушатель событий в случае, если клик вне был отменен.
        // См.: https://github.com/radix-ui/primitives/issues/2171
        ownerDocument.removeEventListener('click', handleClickRef.current);
      }
      isPointerInsideReactTreeRef.current = false;
    };
    /**
     * если этот хук выполняется в компоненте, который монтируется через событие `pointerdown`, событие
     * будет всплывать до документа и вызывать событие `pointerDownOutside`. Мы избегаем
     * этого, задерживая регистрацию слушателя событий на документе.
     * Это не специфично для React, а скорее так работает DOM, например:
     * ```
     * button.addEventListener('pointerdown', () => {
     *   console.log('Я буду логировать');
     *   document.addEventListener('pointerdown', () => {
     *     console.log('Я тоже буду логировать');
     *   })
     * });
     */
    const timerId = window.setTimeout(() => {
      ownerDocument.addEventListener('pointerdown', handlePointerDown);
    }, 0);

    return () => {
      window.clearTimeout(timerId);
      ownerDocument.removeEventListener('pointerdown', handlePointerDown);
      ownerDocument.removeEventListener('click', handleClickRef.current);
    };
  }, [ownerDocument, handlePointerDownOutside]);

  return {
    // гарантирует, что мы проверяем дерево компонентов React (а не только DOM-дерево)
    onPointerDownCapture: () => (isPointerInsideReactTreeRef.current = true),
  };
}

import * as React from 'react';

import { DIALOG_NAME } from './constants';
import { DialogProvider } from './context';
import { ScopedProps } from './types';
import { useControllableState } from '../../../react-utils-extra/hooks/useControllableState';
import { useId } from '../../../react-utils-extra/hooks/useId';
import { Scope } from '../../../react-utils-extra/utils/scopedContext';
import { DismissableLayer } from '../../focus-control/DismissableLayer/DismissableLayer';

type TDialogProps = {
  scopeDialog?: Scope;
  children?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?(open: boolean): void;
  modal?: boolean;
};

export const DialogRoot: React.FC<TDialogProps> = (props: ScopedProps<TDialogProps>) => {
  const { __scopeDialog, children, open: openProp, defaultOpen, onOpenChange, modal = true } = props;
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const contentRef = React.useRef<React.ElementRef<typeof DismissableLayer>>(null);
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  return (
    <DialogProvider
      scope={__scopeDialog}
      triggerRef={triggerRef}
      contentRef={contentRef}
      contentId={useId()}
      titleId={useId()}
      descriptionId={useId()}
      open={open}
      onOpenChange={setOpen}
      onOpenToggle={React.useCallback(() => setOpen(prevOpen => !prevOpen), [setOpen])}
      modal={modal}
    >
      {children}
    </DialogProvider>
  );
};

DialogRoot.displayName = DIALOG_NAME;

export { DialogRoot as Root };

type FocusableTarget = HTMLElement | { focus(): void };

/**
 * Пытается сфокусироваться на первом элементе в списке кандидатов.
 * Останавливается, когда фокус действительно переместился.
 */
export function focusFirst(candidates: HTMLElement[], { select = false } = {}) {
  const previouslyFocusedElement = document.activeElement;
  for (const candidate of candidates) {
    focus(candidate, { select });
    if (document.activeElement !== previouslyFocusedElement) {
      return;
    }
  }
}
/**
 * Возвращает первый и последний элементы, доступные для табуляции, внутри контейнера.
 */
export function getTabbableEdges(container: HTMLElement) {
  const candidates = getTabbableCandidates(container);
  const first = findVisible(candidates, container);
  const last = findVisible(candidates.reverse(), container);

  return [first, last] as const;
}
/**
 * Возвращает список потенциальных кандидатов для табуляции.
 *
 * ПРИМЕЧАНИЕ: Это только приблизительное значение. Например, он не учитывает случаи, когда
 * элементы не видны. Это нельзя легко определить, просто прочитав свойство, но необходимо
 * знание времени выполнения (вычисленные стили и т. д.). Мы рассматриваем эти случаи отдельно.
 *
 * См.: https://developer.mozilla.org/en-US/docs/Web/API/TreeWalker
 * Кредит: https://github.com/discord/focus-layers/blob/master/src/util/wrapFocus.tsx#L1
 */
export function getTabbableCandidates(container: HTMLElement) {
  const nodes: HTMLElement[] = [];
  const walker = document.createTreeWalker(container, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (node: any) => {
      const isHiddenInput = node.tagName === 'INPUT' && node.type === 'hidden';
      if (node.disabled || node.hidden || isHiddenInput) {
        return NodeFilter.FILTER_SKIP;
      }

      // `.tabIndex` не то же самое, что атрибут `tabindex`. Он работает на
      // понимании времени выполнения возможности табуляции, поэтому это автоматически учитывает
      // любой тип элемента, к которому можно перейти с помощью табуляции.
      return node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    },
  });
  while (walker.nextNode()) {
    nodes.push(walker.currentNode as HTMLElement);
  }

  // мы не учитываем порядок узлов с положительным `tabIndex`, так как это
  // ухудшает доступность, если порядок табуляции отличается от визуального порядка.
  return nodes;
}
/**
 * Возвращает первый видимый элемент в списке.
 * ПРИМЕЧАНИЕ: Проверяет видимость только до уровня `container`.
 */
function findVisible(elements: HTMLElement[], container: HTMLElement): HTMLElement | undefined {
  for (const element of elements) {
    // мы прекращаем проверку, если он скрыт на уровне `container` (исключая его)
    if (!isHidden(element, { upTo: container })) {
      return element;
    }
  }

  return undefined;
}

function isHidden(_node: HTMLElement, { upTo }: { upTo?: HTMLElement }) {
  let node = _node;

  if (getComputedStyle(node).visibility === 'hidden') {
    return true;
  }
  while (node) {
    // мы останавливаемся на `upTo` (исключая его)
    if (upTo !== undefined && node === upTo) {
      return false;
    }
    if (getComputedStyle(node).display === 'none') {
      return true;
    }
    node = node.parentElement as HTMLElement;
  }

  return false;
}

function isSelectableInput(element: unknown): element is FocusableTarget & { select: () => void } {
  return element instanceof HTMLInputElement && 'select' in element;
}
export function focus(element?: FocusableTarget | null, { select = false } = {}) {
  // фокусируемся только если этот элемент можно сфокусировать
  if (element && element.focus) {
    const previouslyFocusedElement = document.activeElement;
    // ПРИМЕЧАНИЕ: мы предотвращаем прокрутку при фокусировке, чтобы минимизировать резкие переходы для пользователей
    element.focus({ preventScroll: true });
    // выбираем только если это не тот же элемент, он поддерживает выбор и нам нужно выбрать
    if (element !== previouslyFocusedElement && isSelectableInput(element) && select) {
      element.select();
    }
  }
}

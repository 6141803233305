import { IConfig } from '@cian/config/shared';

import { TOfferDataParam } from '../types';
import { TChatOnboardingByType } from 'shared/types/chatOnboarding';

interface IParams {
  config: IConfig;
  data: TOfferDataParam;
}

// TODO: Удалить в CD-226143/CD-225150. Онбординги на месяц.
export function getOfferChatOnboarding({ config, data }: IParams): TChatOnboardingByType {
  const isEnabled = config.get<boolean>('Newbuilding.CianChatOnboarding.Enabled');

  if (!isEnabled || data.newbuilding) {
    return null;
  }

  const offer = data.offersSerialized.find(({ chat, newbuilding }) =>
    Boolean(newbuilding && newbuilding.isFromBuilder && chat?.canSendMessage),
  );

  if (offer) {
    return {
      onboardingOfferId: offer.id,
      wasChatOpened: false,
    };
  }

  return null;
}

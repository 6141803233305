import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import * as React from 'react';

import { useComposedRefs } from '../../react-utils-extra/hooks/useComposedRefs';
import { useStateMachine } from '../../react-utils-extra/hooks/useStateMachine';

interface IPresenceProps {
  children: React.ReactElement | ((props: { present: boolean }) => React.ReactElement);
  present: boolean;
}

const Presence: React.FC<IPresenceProps> = props => {
  const { present, children } = props;
  const presence = usePresence(present);

  const child = (
    typeof children === 'function' ? children({ present: presence.isPresent }) : React.Children.only(children)
  ) as React.ReactElement;

  const ref = useComposedRefs(presence.ref, getElementRef(child));
  const forceMount = typeof children === 'function';

  return forceMount || presence.isPresent ? React.cloneElement(child, { ref }) : null;
};

Presence.displayName = 'Presence';

/* -------------------------------------------------------------------------------------------------
 * usePresence
 * -----------------------------------------------------------------------------------------------*/

function usePresence(present: boolean) {
  const [node, setNode] = React.useState<HTMLElement>();
  const stylesRef = React.useRef<CSSStyleDeclaration>({} as any);
  const prevPresentRef = React.useRef(present);
  const prevAnimationNameRef = React.useRef<string>('none');
  const initialState = present ? 'mounted' : 'unmounted';
  const [state, send] = useStateMachine(initialState, {
    mounted: {
      UNMOUNT: 'unmounted',
      ANIMATION_OUT: 'unmountSuspended',
    },
    unmountSuspended: {
      MOUNT: 'mounted',
      ANIMATION_END: 'unmounted',
    },
    unmounted: {
      MOUNT: 'mounted',
    },
  });

  React.useEffect(() => {
    const currentAnimationName = getAnimationName(stylesRef.current);
    prevAnimationNameRef.current = state === 'mounted' ? currentAnimationName : 'none';
  }, [state]);

  useIsomorphicLayoutEffect(() => {
    const styles = stylesRef.current;
    const wasPresent = prevPresentRef.current;
    const hasPresentChanged = wasPresent !== present;

    if (hasPresentChanged) {
      const prevAnimationName = prevAnimationNameRef.current;
      const currentAnimationName = getAnimationName(styles);

      if (present) {
        send('MOUNT');
      } else if (currentAnimationName === 'none' || styles?.display === 'none') {
        // Если нет анимации выхода или элемент скрыт, анимации не будут выполняться
        // поэтому мы мгновенно демонтируем
        send('UNMOUNT');
      } else {
        /**
         * Когда `present` изменяется на `false`, мы проверяем изменения в animation-name, чтобы
         * определить, началась ли анимация. Мы выбрали этот подход (чтение
         * вычисленных стилей), потому что нет события `animationrun`, а `animationstart`
         * срабатывает после истечения `animation-delay`, что было бы слишком поздно.
         */
        const isAnimating = prevAnimationName !== currentAnimationName;

        if (wasPresent && isAnimating) {
          send('ANIMATION_OUT');
        } else {
          send('UNMOUNT');
        }
      }

      prevPresentRef.current = present;
    }
  }, [present, send]);

  useIsomorphicLayoutEffect(() => {
    if (node) {
      let timeoutId: number;
      const ownerWindow = node.ownerDocument.defaultView ?? window;
      /**
       * Запуск ANIMATION_OUT во время ANIMATION_IN вызовет событие `animationcancel`
       * для ANIMATION_IN после того, как мы вошли в состояние `unmountSuspended`. Поэтому,
       * мы убеждаемся, что запускаем ANIMATION_END только для текущей активной анимации.
       */
      const handleAnimationEnd = (event: AnimationEvent) => {
        const currentAnimationName = getAnimationName(stylesRef.current);
        const isCurrentAnimation = currentAnimationName.includes(event.animationName);
        if (event.target === node && isCurrentAnimation) {
          // С React 18 concurrency это обновление применяется через кадр после
          // окончания анимации, создавая вспышку видимого контента. Установив
          // режим заполнения анимации на "forwards", мы заставляем узел сохранять
          // стили последнего ключевого кадра, устраняя вспышку.
          //
          // Ранее мы сбрасывали обновление через ReactDom.flushSync, но с
          // анимациями выхода это приводило к удалению узла из DOM до того,
          // как синтетическое событие animationEnd было отправлено, что означало,
          // что пользовательские обработчики событий не вызывались.
          // https://github.com/radix-ui/primitives/pull/1849
          send('ANIMATION_END');
          if (!prevPresentRef.current) {
            const currentFillMode = node.style.animationFillMode;
            node.style.animationFillMode = 'forwards';
            // Сброс стиля после того, как узел успел демонтироваться (для случаев,
            // когда компонент решает не демонтироваться). Делать это раньше, чем `setTimeout`
            // (например, с `requestAnimationFrame`) все еще вызывает вспышку.
            timeoutId = ownerWindow.setTimeout(() => {
              if (node.style.animationFillMode === 'forwards') {
                node.style.animationFillMode = currentFillMode;
              }
            });
          }
        }
      };
      const handleAnimationStart = (event: AnimationEvent) => {
        if (event.target === node) {
          // если произошла анимация, сохраняем ее имя как предыдущее.
          prevAnimationNameRef.current = getAnimationName(stylesRef.current);
        }
      };
      node.addEventListener('animationstart', handleAnimationStart);
      node.addEventListener('animationcancel', handleAnimationEnd);
      node.addEventListener('animationend', handleAnimationEnd);

      return () => {
        ownerWindow.clearTimeout(timeoutId);
        node.removeEventListener('animationstart', handleAnimationStart);
        node.removeEventListener('animationcancel', handleAnimationEnd);
        node.removeEventListener('animationend', handleAnimationEnd);
      };
    } else {
      // Переход в состояние unmounted, если узел удален преждевременно.
      // Мы избегаем делать это во время очистки, так как узел может измениться, но все еще существовать.
      send('ANIMATION_END');

      return;
    }
  }, [node, send]);

  return {
    isPresent: ['mounted', 'unmountSuspended'].includes(state),
    ref: React.useCallback((node: HTMLElement) => {
      if (node) {
        stylesRef.current = getComputedStyle(node);
      }
      setNode(node);
    }, []),
  };
}

/* -----------------------------------------------------------------------------------------------*/

function getAnimationName(styles?: CSSStyleDeclaration) {
  return styles?.animationName || 'none';
}

// До React 19 доступ к `element.props.ref` вызовет предупреждение и предложит использовать `element.ref`
// После React 19 доступ к `element.ref` делает противоположное.
// https://github.com/facebook/react/pull/28348
//
// Доступ к ref с использованием метода, который не вызывает предупреждение.
function getElementRef(element: React.ReactElement) {
  // React <=18 в DEV
  let getter = Object.getOwnPropertyDescriptor(element.props, 'ref')?.get;
  let mayWarn = getter && 'isReactWarning' in getter && getter.isReactWarning;
  if (mayWarn) {
    return (element as any).ref;
  }

  // React 19 в DEV
  getter = Object.getOwnPropertyDescriptor(element, 'ref')?.get;
  mayWarn = getter && 'isReactWarning' in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }

  // Не DEV
  return element.props.ref || (element as any).ref;
}

export { Presence };
export type { IPresenceProps as PresenceProps };

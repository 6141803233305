import * as React from 'react';

import { composeRefs } from '../../react-utils-extra/hooks/useComposedRefs';

interface ISlotCloneProps {
  children: React.ReactNode;
}

const SlotClone = React.forwardRef<unknown, ISlotCloneProps>((props, forwardedRef) => {
  const { children, ...slotProps } = props;

  if (React.isValidElement(children)) {
    const childrenRef = getElementRef(children);

    return React.cloneElement(children, {
      ...mergeProps(slotProps, children.props),
      // @ts-expect-error `ref` не является допустимым свойством для ReactElement, но используется в `getElementRef`
      ref: forwardedRef ? composeRefs(forwardedRef, childrenRef) : childrenRef,
    });
  }

  return React.Children.count(children) > 1 ? React.Children.only(null) : null;
});

SlotClone.displayName = 'SlotClone';

const Slottable = ({ children }: { children: React.ReactNode }) => {
  return children;
};

interface ISlotProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
}

const Slot = React.forwardRef<HTMLElement, ISlotProps>((props, forwardedRef) => {
  const { children, ...slotProps } = props;
  const childrenArray = React.Children.toArray(children);
  const slottable = childrenArray.find(isSlottable);

  if (slottable) {
    // новый элемент для рендеринга - это тот, который передан как дочерний элемент `Slottable`
    const newElement = slottable.props.children as React.ReactNode;

    const newChildren = childrenArray.map(child => {
      if (child === slottable) {
        // поскольку новый элемент будет рендериться, нас интересуют только его дочерние элементы (`newElement.props.children`)
        if (React.Children.count(newElement) > 1) {
          return React.Children.only(null);
        }

        return React.isValidElement(newElement) ? (newElement.props.children as React.ReactNode) : null;
      } else {
        return child;
      }
    });

    return (
      <SlotClone {...slotProps} ref={forwardedRef}>
        {React.isValidElement(newElement) ? React.cloneElement(newElement, undefined, newChildren) : null}
      </SlotClone>
    );
  }

  return (
    <SlotClone {...slotProps} ref={forwardedRef}>
      {children}
    </SlotClone>
  );
});

Slot.displayName = 'Slot';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyProps = Record<string, any>;

function isSlottable(child: React.ReactNode): child is React.ReactElement {
  return React.isValidElement(child) && child.type === Slottable;
}

function mergeProps(slotProps: AnyProps, childProps: AnyProps) {
  // все свойства дочернего элемента должны переопределять
  const overrideProps = { ...childProps };

  for (const propName in childProps) {
    if (Object.prototype.hasOwnProperty.call(childProps, propName)) {
      const slotPropValue = slotProps[propName];
      const childPropValue = childProps[propName];

      const isHandler = /^on[A-Z]/.test(propName);
      if (isHandler) {
        // если обработчик существует в обоих, мы их объединяем
        if (slotPropValue && childPropValue) {
          overrideProps[propName] = (...args: unknown[]) => {
            childPropValue(...args);
            slotPropValue(...args);
          };
        }
        // но если он существует только в слоте, мы используем только его
        else if (slotPropValue) {
          overrideProps[propName] = slotPropValue;
        }
      }
      // если это `style`, мы их объединяем
      else if (propName === 'style') {
        overrideProps[propName] = { ...slotPropValue, ...childPropValue };
      } else if (propName === 'className') {
        overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(' ');
      }
    }
  }

  return { ...slotProps, ...overrideProps };
}

// До React 19 доступ к `element.props.ref` выдаст предупреждение и предложит использовать `element.ref`
// После React 19 доступ к `element.ref` делает противоположное.
// https://github.com/facebook/react/pull/28348
//
// Доступ к ref с использованием метода, который не вызывает предупреждение.
function getElementRef(element: React.ReactElement) {
  // React <=18 в DEV
  let getter = Object.getOwnPropertyDescriptor(element.props, 'ref')?.get;
  let mayWarn = getter && 'isReactWarning' in getter && getter.isReactWarning;
  if (mayWarn) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (element as any).ref;
  }

  // React 19 в DEV
  getter = Object.getOwnPropertyDescriptor(element, 'ref')?.get;
  mayWarn = getter && 'isReactWarning' in getter && getter.isReactWarning;
  if (mayWarn) {
    return element.props.ref;
  }

  // Не DEV
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return element.props.ref || (element as any).ref;
}

export { Slot, Slottable };
export type { ISlotProps as SlotProps };

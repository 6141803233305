import * as React from 'react';

import { CLOSE_NAME } from './constants';
import { useDialogContext } from './context';
import { ScopedProps } from './types';
import { composeEventHandlers } from '../../../react-utils-extra/utils/composeEventHandlers';
import { DomPrimitive } from '../../primitives/DomPrimitive';

type TDialogCloseElement = React.ElementRef<typeof DomPrimitive.button>;
type TDialogCloseProps = React.ComponentPropsWithoutRef<typeof DomPrimitive.button> & {};

export const DialogClose = React.forwardRef<TDialogCloseElement, ScopedProps<TDialogCloseProps>>(
  (props, forwardedRef) => {
    const { __scopeDialog, ...closeProps } = props;
    const context = useDialogContext(CLOSE_NAME, __scopeDialog);

    return (
      <DomPrimitive.button
        type="button"
        {...closeProps}
        ref={forwardedRef}
        onClick={composeEventHandlers(props.onClick, () => context.onOpenChange(false))}
      />
    );
  },
);

DialogClose.displayName = CLOSE_NAME;

export { DialogClose as Close };
export type { TDialogCloseElement, TDialogCloseProps };

import * as React from 'react';

/** Количество компонентов, которые запросили FocusGuards */
const UI_KIT_FOCUS_GUARD_ATTRIBUT = 'data-cian-ui-kit-focus-guard';
let count = 0;

function FocusGuards<T>(props: React.PropsWithChildren<T>) {
  useFocusGuards();

  return props.children;
}

/**
 * Вставляет пару фокусных охранников на границы всего DOM-дерева,
 * чтобы гарантировать, что события `focusin` и `focusout` могут быть пойманы последовательно.
 */
function useFocusGuards() {
  React.useEffect(() => {
    const edgeGuards = document.querySelectorAll(`[${UI_KIT_FOCUS_GUARD_ATTRIBUT}]`);
    document.body.insertAdjacentElement('afterbegin', edgeGuards[0] ?? createFocusGuard());
    document.body.insertAdjacentElement('beforeend', edgeGuards[1] ?? createFocusGuard());
    count++;

    return () => {
      if (count === 1) {
        document.querySelectorAll(`[${UI_KIT_FOCUS_GUARD_ATTRIBUT}]`).forEach(node => node.remove());
      }
      count--;
    };
  }, []);
}

function createFocusGuard() {
  const element = document.createElement('span');
  element.setAttribute(UI_KIT_FOCUS_GUARD_ATTRIBUT, '');
  element.tabIndex = 0;
  element.style.outline = 'none';
  element.style.opacity = '0';
  element.style.position = 'fixed';
  element.style.pointerEvents = 'none';

  return element;
}

export { FocusGuards, useFocusGuards };

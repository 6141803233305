import * as React from 'react';

type PossibleRef<T> = React.Ref<T> | undefined;

/**
 * Установить данную ссылку на данное значение
 * Этот утилита обрабатывает разные типы ссылок: callback refs и RefObject(s)
 */
function setRef<T>(ref: PossibleRef<T>, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null && ref !== undefined) {
    (ref as React.MutableRefObject<T>).current = value;
  }
}

/**
 * Утилита для объединения нескольких ссылок вместе
 * Принимает callback refs и RefObject(s)
 */
function composeRefs<T>(...refs: PossibleRef<T>[]) {
  return (node: T) => refs.forEach(ref => setRef(ref, node));
}

/**
 * Пользовательский хук, который объединяет несколько ссылок
 * Принимает callback refs и RefObject(s)
 */
function useComposedRefs<T>(...refs: PossibleRef<T>[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(composeRefs(...refs), refs);
}

export { composeRefs, useComposedRefs };

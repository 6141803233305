import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import * as React from 'react';
import { createPortal } from 'react-dom';

import { DomPrimitive } from './DomPrimitive';

const PORTAL_NAME = 'Portal';

type PortalElement = React.ElementRef<typeof DomPrimitive.div>;
type PrimitiveDivProps = React.ComponentPropsWithoutRef<typeof DomPrimitive.div>;
interface IPortalProps extends PrimitiveDivProps {
  /**
   * An optional container where the portaled content should be appended.
   */
  container?: Element | DocumentFragment | null;
}

const Portal = React.forwardRef<PortalElement, IPortalProps>((props, forwardedRef) => {
  const { container: containerProp, ...portalProps } = props;
  const [mounted, setMounted] = React.useState(false);
  useIsomorphicLayoutEffect(() => setMounted(true), []);
  const container = containerProp || (mounted && globalThis?.document?.body);

  return container ? createPortal(<DomPrimitive.div {...portalProps} ref={forwardedRef} />, container) : null;
});

Portal.displayName = PORTAL_NAME;

export { Portal };
export type { IPortalProps as PortalProps };

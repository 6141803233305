import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import * as React from 'react';

// Мы используем `.trim().toString()`, чтобы предотвратить попытки сборщиков импортировать `useId` из 'react'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useReactId = (React as any)[' useId '.trim().toString()] || (() => undefined);
let count = 0;

function useId(deterministicId?: string): string {
  const [id, setId] = React.useState<string | undefined>(useReactId());
  // Версии React старше 18 будут иметь только клиентские идентификаторы.
  useIsomorphicLayoutEffect(() => {
    if (!deterministicId) {
      setId(reactId => reactId ?? String(count++));
    }
  }, [deterministicId]);

  return deterministicId || (id ? `radix-${id}` : '');
}

export { useId };

type FocusScopeAPI = { paused: boolean; pause(): void; resume(): void };

export const focusScopesStack = createFocusScopesStack();

function createFocusScopesStack() {
  /** Стек областей фокуса, с активной областью наверху */
  let stack: FocusScopeAPI[] = [];

  return {
    add(focusScope: FocusScopeAPI) {
      // приостановить текущую активную область фокуса (наверху стека)
      const activeFocusScope = stack[0];
      if (focusScope !== activeFocusScope) {
        activeFocusScope?.pause();
      }
      // удалить в случае, если она уже существует (потому что мы добавим её снова наверх стека)
      stack = arrayRemove(stack, focusScope);
      stack.unshift(focusScope);
    },

    remove(focusScope: FocusScopeAPI) {
      stack = arrayRemove(stack, focusScope);
      stack[0]?.resume();
    },
  };
}

function arrayRemove<T>(array: T[], item: T) {
  const updatedArray = [...array];
  const index = updatedArray.indexOf(item);
  if (index !== -1) {
    updatedArray.splice(index, 1);
  }

  return updatedArray;
}

export function removeLinks(items: HTMLElement[]) {
  return items.filter(item => item.tagName !== 'A');
}

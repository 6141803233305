import * as React from 'react';

import { PORTAL_NAME } from './constants';
import { PortalProvider, useDialogContext } from './context';
import { ScopedProps } from './types';
import { Presence } from '../../presence/Presence';
import { Portal } from '../../primitives';

type TPortalProps = React.ComponentPropsWithoutRef<typeof Portal>;

type TDialogPortalProps = {
  children?: React.ReactNode;
  /**
   * Укажите элемент контейнера, в который будет помещено содержимое.
   */
  container?: TPortalProps['container'];
  /**
   * Используется для принудительного монтирования, когда требуется больший контроль. Полезно при
   * управлении анимацией с помощью библиотек анимации React.
   */
  forceMount?: true;
};

export const DialogPortal: React.FC<TDialogPortalProps> = (props: ScopedProps<TDialogPortalProps>) => {
  const { __scopeDialog, forceMount, children, container } = props;
  const context = useDialogContext(PORTAL_NAME, __scopeDialog);

  return (
    <PortalProvider scope={__scopeDialog} forceMount={forceMount}>
      {React.Children.map(children, child => (
        <Presence present={forceMount || context.open}>
          <Portal asChild container={container}>
            {child}
          </Portal>
        </Presence>
      ))}
    </PortalProvider>
  );
};

DialogPortal.displayName = PORTAL_NAME;

export { DialogPortal as Portal };
export type { TDialogPortalProps };

import * as React from 'react';

import { useDialogContext } from './context';
import { ScopedProps } from './types';
import { DomPrimitive } from '../../primitives';

const TITLE_NAME = 'DialogTitle';

type TDialogTitleElement = React.ElementRef<typeof DomPrimitive.h2>;
type TPrimitiveHeading2Props = React.ComponentPropsWithoutRef<typeof DomPrimitive.h2>;
type TDialogTitleProps = TPrimitiveHeading2Props & {};

const DialogTitle = React.forwardRef<TDialogTitleElement, TDialogTitleProps>(
  (props: ScopedProps<TDialogTitleProps>, forwardedRef) => {
    const { __scopeDialog, ...titleProps } = props;
    const context = useDialogContext(TITLE_NAME, __scopeDialog);

    return <DomPrimitive.h2 id={context.titleId} {...titleProps} ref={forwardedRef} />;
  },
);

DialogTitle.displayName = TITLE_NAME;

export { DialogTitle as Title };
export type { TDialogTitleProps, TDialogTitleElement };

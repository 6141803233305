import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Slot } from '../slot/Slot';

const NODES = [
  'a',
  'button',
  'div',
  'form',
  'h2',
  'h3',
  'img',
  'input',
  'label',
  'li',
  'nav',
  'ol',
  'p',
  'span',
  'svg',
  'ul',
] as const;

type DomPrimitives = { [E in (typeof NODES)[number]]: IPrimitiveForwardRefComponent<E> };
type DomPrimitivePropsWithRef<E extends React.ElementType> = React.ComponentPropsWithRef<E> & {
  asChild?: boolean;
};

interface IPrimitiveForwardRefComponent<E extends React.ElementType>
  extends React.ForwardRefExoticComponent<DomPrimitivePropsWithRef<E>> {}

const DomPrimitive = NODES.reduce((primitive, node) => {
  const Node = React.forwardRef(
    (props: DomPrimitivePropsWithRef<typeof node>, forwardedRef: React.Ref<DomPrimitives>) => {
      const { asChild, ...primitiveProps } = props;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const Comp: any = asChild ? Slot : node;

      return <Comp {...primitiveProps} ref={forwardedRef} />;
    },
  );

  Node.displayName = `Primitive.${node}`;

  return { ...primitive, [node]: Node };
}, {} as DomPrimitives);

/* -------------------------------------------------------------------------------------------------
 * Утилиты
 * -----------------------------------------------------------------------------------------------*/

/**
 * Сброс отправки пользовательского события
 * https://github.com/radix-ui/primitives/pull/1378
 *
 * React группирует *все* обработчики событий с версии 18, что вводит определенные соображения при использовании пользовательских типов событий.
 *
 * Внутренне, React приоритизирует события в следующем порядке:
 *  - дискретные
 *  - непрерывные
 *  - по умолчанию
 *
 * https://github.com/facebook/react/blob/a8a4742f1c54493df00da648a3f9d26e3db9c8b5/packages/react-dom/src/events/ReactDOMEventListener.js#L294-L350
 *
 * `дискретные` - это важное различие, так как обновления в этих событиях применяются немедленно.
 * Однако, React не может определить приоритет пользовательских типов событий из-за того, как они обнаруживаются внутренне.
 * Из-за этого возможно, что обновления от пользовательских событий будут неожиданно сгруппированы, когда они отправляются другим `дискретным` событием.
 *
 * Чтобы гарантировать, что обновления от пользовательских событий применяются предсказуемо, нам нужно вручную сбросить группу.
 * Эта утилита должна использоваться при отправке пользовательского события из другого `дискретного` события, эта утилита не нужна при отправке известных типов событий или при отправке пользовательского типа внутри недискретного события.
 * Например:
 *
 * отправка известного клика 👎
 * target.dispatchEvent(new Event('click'))
 *
 * отправка пользовательского типа внутри недискретного события 👎
 * onScroll={(event) => event.target.dispatchEvent(new CustomEvent('customType'))}
 *
 * отправка пользовательского типа внутри `дискретного` события 👍
 * onPointerDown={(event) => dispatchDiscreteCustomEvent(event.target, new CustomEvent('customType'))}
 *
 * Примечание: хотя React классифицирует события `focus`, `focusin` и `focusout` как `дискретные`, не рекомендуется использовать эту утилиту с ними. Это потому, что возможно, что эти обработчики будут вызваны неявно во время рендеринга, например, когда фокус находится внутри компонента при его размонтировании или при управлении фокусом при монтировании.
 */

function dispatchDiscreteCustomEvent<E extends CustomEvent>(target: E['target'], event: E) {
  if (target) {
    ReactDOM.flushSync(() => target.dispatchEvent(event));
  }
}

export { DomPrimitive, dispatchDiscreteCustomEvent };
export type { DomPrimitivePropsWithRef };

import * as React from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { OVERLAY_NAME } from './constants';
import { useDialogContext, usePortalContext } from './context';
import { ScopedProps } from './types';
import { getDataStateValue } from './utils';
import { Presence } from '../../presence/Presence';
import { DomPrimitive } from '../../primitives';
import { Slot } from '../../slot/Slot';

type TDialogOverlayImplElement = React.ElementRef<typeof DomPrimitive.div>;
type TPrimitiveDivProps = React.ComponentPropsWithoutRef<typeof DomPrimitive.div>;
type TDialogOverlayImplProps = TPrimitiveDivProps & {};

const DialogOverlayImpl = React.forwardRef<TDialogOverlayImplElement, ScopedProps<TDialogOverlayImplProps>>(
  (props, forwardedRef) => {
    const { __scopeDialog, ...overlayProps } = props;
    const context = useDialogContext(OVERLAY_NAME, __scopeDialog);

    return (
      // Убедитесь, что `Content` прокручивается, даже если он не находится внутри `RemoveScroll`
      // т.е. когда `Overlay` и `Content` являются соседями
      <RemoveScroll as={Slot} allowPinchZoom shards={[context.contentRef]}>
        <DomPrimitive.div
          data-state={getDataStateValue(context.open)}
          {...overlayProps}
          ref={forwardedRef}
          // Мы повторно включаем события указателя, предотвращенные `Dialog.Content`, чтобы разрешить прокрутку наложения.
          style={{ pointerEvents: 'auto', ...overlayProps.style }}
        />
      </RemoveScroll>
    );
  },
);

DialogOverlayImpl.displayName = `${OVERLAY_NAME}.Impl`;

type TDialogOverlayElement = TDialogOverlayImplElement;
type TDialogOverlayProps = TDialogOverlayImplProps & {
  /**
   * Используется для принудительного монтирования, когда требуется больший контроль. Полезно при
   * управлении анимацией с помощью библиотек анимации React.
   */
  forceMount?: true;
};

export const DialogOverlay = React.forwardRef<TDialogOverlayElement, ScopedProps<TDialogOverlayProps>>(
  (props, forwardedRef) => {
    const portalContext = usePortalContext(OVERLAY_NAME, props.__scopeDialog);
    const { forceMount = portalContext.forceMount, ...overlayProps } = props;
    const context = useDialogContext(OVERLAY_NAME, props.__scopeDialog);

    return context.modal ? (
      <Presence present={forceMount || context.open}>
        <DialogOverlayImpl {...overlayProps} ref={forwardedRef} />
      </Presence>
    ) : null;
  },
);

DialogOverlay.displayName = OVERLAY_NAME;

export { DialogOverlay as Overlay };
export type { TDialogOverlayProps, TDialogOverlayElement };

import * as React from 'react';

import { EActionType } from '../../../../../../../shared/repositories/search-offers.legacy/v2/types/dailyrentData';
import { useIsDailyrentOnlineBookingEnabled } from '../../../../../../../shared/utils/featureToggles/useIsDailyrentOnlineBookingEnabled';
import { DailyrentActionsLayout } from '../../../../components/DailyrentActionsLayout';
import { useOfferContext } from '../../../../context';
import { DailyrentPartnerButtonContainer } from '../AgentBookButtonContainer';
import { AgentPhoneButtonContainer } from '../AgentPhoneButtonContainer';
import { AgentShowMoreOffersContainer } from '../AgentShowMoreOffersContainer';
import { DailyrentOnlineBookingButtonContainer } from '../DailyrentOnlineBookingButtonContainer';
import { OfferChatButtonContainer } from '../OfferChatButtonContainer';

export const DailyrentActionButtonsContainer = () => {
  const offerData = useOfferContext();
  const {
    offer: { dailyrent },
  } = offerData;
  const isDailyrentOnlineBookingEnabled = useIsDailyrentOnlineBookingEnabled();
  const actionType = dailyrent?.actionType || EActionType.Contact;

  const isOnlineBookingActionType = [EActionType.OtaBooking, EActionType.OtaCheckDates].includes(actionType);
  const showOnlineBookingButtons = isDailyrentOnlineBookingEnabled && isOnlineBookingActionType;

  const isPartnerActionType = [EActionType.PartnerBooking, EActionType.PartnerCheckDates].includes(actionType);

  if (showOnlineBookingButtons) {
    return (
      <DailyrentActionsLayout>
        <AgentShowMoreOffersContainer />
        <DailyrentOnlineBookingButtonContainer />
        <OfferChatButtonContainer />
      </DailyrentActionsLayout>
    );
  }

  if (isPartnerActionType) {
    return (
      <DailyrentActionsLayout>
        <DailyrentPartnerButtonContainer />
      </DailyrentActionsLayout>
    );
  }

  return (
    <DailyrentActionsLayout>
      <AgentShowMoreOffersContainer />
      <AgentPhoneButtonContainer />
      <OfferChatButtonContainer />
    </DailyrentActionsLayout>
  );
};

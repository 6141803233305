/* eslint-disable max-lines */
import { isAvailable, isAvailableStrict, oneOf, isAvailableRegion, everyOf } from './availability';
import { MOSCOW_ID, PIK_PROMO_REGIONS } from './constants';
import { terms } from './constructors';
import { getApartments } from './getApartments';
import { getTermsValue } from './helpers';
import { FDealType, FOfferType, TJsonQuery, TJsonQueryKeys } from './types';
import { dealTypeFromJsonQuery, getAllowedPromoSearchTypes, offerTypeFromJsonQuery } from './utils';
import { EContractType, EHouseMaterial, ELandStatusType, ERoomType, ESaleType } from '../api-models/common/json_query';

interface IPropertyConfig {
  availability?(jsonQuery: TJsonQuery): boolean;
  converter?(jsonQuery: TJsonQuery): TJsonQuery;
}

export const PROPERTIES: { [key in Required<TJsonQueryKeys>]: IPropertyConfig } = {
  _type: {},
  apartment: {
    availability: isAvailable(FDealType.Any, FOfferType.Flat | FOfferType.FlatShared),
  },
  flat_share: {},
  balconies: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  bath: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  bathhouse: { availability: isAvailable(FDealType.Any, FOfferType.Suburban & ~FOfferType.Land) },
  bbox: {},
  beds_count: {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Any),
  },
  dates: {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Any),
  },
  bedroom_total: {
    availability: oneOf(
      isAvailable(FDealType.Sale | FDealType.RentLongterm, FOfferType.Suburban & ~FOfferType.Land),
      isAvailable(FDealType.RentDaily, FOfferType.House),
    ),
  },
  bs_center: { availability: isAvailable(FDealType.Any, FOfferType.Commercial) },
  bs_center_id: { availability: isAvailable(FDealType.Any, FOfferType.Commercial) },
  build_quarter: {},
  build_year: {},
  building_class_type: {
    availability: isAvailable(FDealType.Any, FOfferType.Office | FOfferType.TradeArea | FOfferType.Building),
  },
  building_class_type__warehouse: {
    availability: isAvailable(FDealType.Any, FOfferType.Manufacture | FOfferType.Warehouse),
  },
  building_cranage_types_type: {
    availability: isAvailable(FDealType.Any, FOfferType.Manufacture | FOfferType.Warehouse),
  },
  building_gates_type: {
    availability: isAvailable(FDealType.Any, FOfferType.Manufacture | FOfferType.Warehouse),
  },
  building_heating_type: {
    availability: isAvailable(FDealType.Any, FOfferType.Manufacture | FOfferType.Warehouse),
  },
  building_house_line_type: { availability: isAvailable(FDealType.Any, FOfferType.Building) },
  building_house_line_type__shopping_area: { availability: isAvailable(FDealType.Any, FOfferType.TradeArea) },
  building_lift_types_type: {
    availability: isAvailable(FDealType.Any, FOfferType.Manufacture | FOfferType.Warehouse),
  },
  building_status: {},
  building_type2: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.Office |
        FOfferType.TradeArea |
        FOfferType.Manufacture |
        FOfferType.Warehouse |
        FOfferType.FreeAppointmentObject |
        FOfferType.PublicCatering |
        FOfferType.AutoService |
        FOfferType.DomesticServices,
    ),
  },
  building_type__building: { availability: isAvailable(FDealType.Any, FOfferType.Building) },
  category: {},
  ceiling_height: {
    availability: isAvailable(FDealType.Any, FOfferType.Urban | FOfferType.Manufacture | FOfferType.Warehouse),
  },
  cl: {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Any & ~FOfferType.Land),
  },
  commission_type: {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Any & ~FOfferType.Land),
  },
  composition: { availability: isAvailable(FDealType.Rent, FOfferType.Room) },
  condition_type: { availability: isAvailable(FDealType.Any, FOfferType.Office) },
  condition_type__free_appointment_object: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.TradeArea |
        FOfferType.FreeAppointmentObject |
        FOfferType.PublicCatering |
        FOfferType.AutoService |
        FOfferType.DomesticServices |
        FOfferType.Building |
        FOfferType.Business,
    ),
  },
  condition_type__industry: {
    availability: isAvailable(FDealType.Any, FOfferType.Manufacture | FOfferType.Warehouse),
  },
  conditioner: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  contact: {},
  contract: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.Office |
        FOfferType.TradeArea |
        FOfferType.FreeAppointmentObject |
        FOfferType.AutoService |
        FOfferType.Warehouse |
        FOfferType.Manufacture |
        FOfferType.DomesticServices |
        FOfferType.PublicCatering,
    ),
    converter: jsonQuery => {
      const nextDealType = dealTypeFromJsonQuery(jsonQuery);

      let nextContractTypes: EContractType[] | null =
        (jsonQuery.contract &&
          jsonQuery.contract.value.filter((type: EContractType) => {
            if (nextDealType & FDealType.Rent) {
              return ![EContractType.ObjSale, EContractType.PermTransfer].includes(type);
            } else if (nextDealType & FDealType.Sale) {
              return ![EContractType.DirectRent, EContractType.SubRent].includes(type);
            }

            return true;
          })) ||
        null;

      if (nextContractTypes && nextContractTypes.length === 0) {
        nextContractTypes = null;
      }

      return { ...jsonQuery, contract: terms(nextContractTypes) };
    },
  },
  coworking_id: { availability: isAvailable(FDealType.RentLongterm, FOfferType.Coworking) },
  coworking_offer_type: { availability: isAvailable(FDealType.RentLongterm, FOfferType.Coworking) },
  currency: {},
  deal_rent: { availability: isAvailable(FDealType.RentLongterm, FOfferType.Flat) },
  demolished_in_moscow_programm: {
    availability: everyOf(
      isAvailable(FDealType.Sale | FDealType.RentLongterm, FOfferType.Urban & ~FOfferType.FlatNew & ~FOfferType.Bed),
      isAvailableRegion([MOSCOW_ID]),
    ),
  },
  description_exclude: {},
  description_include: {},
  developer_sort_type: {},
  dish_washer: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  domrf: {
    availability: everyOf(isAvailable(FDealType.RentLongterm, FOfferType.Flat), isAvailableRegion([MOSCOW_ID])),
  },
  electricity: { availability: isAvailable(FDealType.Any, FOfferType.Suburban) },
  engine_version: {},
  enter: { availability: isAvailable(FDealType.Any, FOfferType.Office) },
  estate_type: { availability: isAvailable(FDealType.Any, FOfferType.Business) },
  floor: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.Urban |
        FOfferType.Office |
        FOfferType.TradeArea |
        FOfferType.Manufacture |
        FOfferType.Warehouse |
        FOfferType.FreeAppointmentObject |
        FOfferType.PublicCatering |
        FOfferType.AutoService |
        FOfferType.DomesticServices |
        FOfferType.Business,
    ),
  },
  floorn: {
    availability: isAvailable(
      FDealType.Any,
      (FOfferType.Residential & ~FOfferType.Land) |
        FOfferType.Office |
        FOfferType.TradeArea |
        FOfferType.Manufacture |
        FOfferType.Warehouse |
        FOfferType.FreeAppointmentObject |
        FOfferType.Building |
        FOfferType.Business,
    ),
  },
  foot_min: { availability: isAvailable(FDealType.Any, FOfferType.Any) },
  for_day: { availability: isAvailable(FDealType.Rent, FOfferType.Residential) },
  from_developer: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  from_jk: {},
  from_mcad_km: { availability: isAvailable(FDealType.Any, FOfferType.Suburban) },
  from_offrep: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.Office | FOfferType.Warehouse | FOfferType.TradeArea | FOfferType.FreeAppointmentObject,
    ),
  },
  garage: { availability: isAvailable(FDealType.Any, FOfferType.Suburban & ~FOfferType.Land) },
  garage_garage_type: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  garage_material: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  garage_status: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  garage_type: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  gas: { availability: isAvailable(FDealType.Any, FOfferType.Suburban) },
  geo: {},
  hand_over: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  has_decoration: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  has_drainage: { availability: isAvailable(FDealType.Any, FOfferType.Suburban) },
  has_electricity: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  has_equipment: { availability: isAvailable(FDealType.Any, FOfferType.Business) },
  has_extinguishing_system: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  has_flat_tour_booking: {},
  has_fridge: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  has_furniture: {
    availability: oneOf(
      isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land),
      isAvailable(FDealType.Any, FOfferType.Office | FOfferType.TradeArea | FOfferType.Building | FOfferType.Business),
    ),
  },
  has_heating: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  has_kitchen_furniture: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  has_light: { availability: isAvailable(FDealType.Any, FOfferType.Garage) },
  has_profitbase_booking: { availability: isAvailable(FDealType.Sale, FOfferType.FlatNew) },
  has_video: {},
  has_washer: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  has_water: {
    availability: oneOf(isAvailable(FDealType.Any, FOfferType.Suburban), isAvailable(FDealType.Any, FOfferType.Garage)),
  },
  heating_source_type: { availability: isAvailable(FDealType.Any, FOfferType.Suburban & ~FOfferType.Land) },
  house_material: {
    availability: isAvailable(FDealType.Any, FOfferType.Residential & ~FOfferType.Land),
    converter: jsonQuery => {
      const nextOfferType = offerTypeFromJsonQuery(jsonQuery);

      let nextHouseMaterials: EHouseMaterial[] | null =
        (jsonQuery.house_material &&
          jsonQuery.house_material.value.filter((type: EHouseMaterial) => {
            if (nextOfferType === FOfferType.FlatNew) {
              return [EHouseMaterial.Panel, EHouseMaterial.Brick, EHouseMaterial.Monolith].includes(type);
            } else if ((nextOfferType & FOfferType.Suburban) !== 0) {
              return [
                EHouseMaterial.Wood,
                EHouseMaterial.Brick,
                EHouseMaterial.Monolith,
                EHouseMaterial.Shield,
                EHouseMaterial.Wireframe,
                EHouseMaterial.AerocreteBlock,
                EHouseMaterial.GasSilicateBlock,
                EHouseMaterial.FoamConcreteBlock,
              ].includes(type);
            }

            return true;
          })) ||
        null;

      if (nextHouseMaterials && nextHouseMaterials.length === 0) {
        nextHouseMaterials = null;
      }

      return { ...jsonQuery, house_material: terms(nextHouseMaterials) };
    },
  },
  house_year: {
    availability: isAvailable(
      FDealType.Any,
      (FOfferType.Residential & ~FOfferType.FlatNew & ~FOfferType.Land) |
        (FOfferType.Commercial & ~FOfferType.CommercialLand & ~FOfferType.Business),
    ),
  },
  id: {},
  id_user: {},
  identical_id: {},
  include_new_moscow: {},
  input_type: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.TradeArea |
        FOfferType.FreeAppointmentObject |
        FOfferType.PublicCatering |
        FOfferType.AutoService |
        FOfferType.DomesticServices |
        FOfferType.Building |
        FOfferType.Business,
    ),
  },
  internet: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  ipoteka: { availability: isAvailable(FDealType.Sale, FOfferType.Urban) },
  is_basement: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.Office |
        FOfferType.TradeArea |
        FOfferType.Manufacture |
        FOfferType.Warehouse |
        FOfferType.FreeAppointmentObject |
        FOfferType.PublicCatering |
        FOfferType.AutoService |
        FOfferType.DomesticServices,
    ),
  },
  is_black_friday: { availability: isAvailable(FDealType.Sale, FOfferType.Flat | FOfferType.FlatNew) },
  is_by_homeowner: { availability: isAvailable(FDealType.Any, FOfferType.Residential & ~FOfferType.FlatNew) },
  is_dacha: { availability: isAvailableStrict(FDealType.Sale, FOfferType.House) },
  is_first_floor: {
    availability: isAvailable(FDealType.Any, FOfferType.Urban),
  },
  is_in_hidden_base: {},
  is_in_kp: {},
  is_pik_promo: {
    availability: everyOf(isAvailableStrict(FDealType.Sale, FOfferType.FlatNew), isAvailableRegion(PIK_PROMO_REGIONS)),
  },
  is_sales_leader: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  is_sales_start: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  is_semibasement: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.Office |
        FOfferType.TradeArea |
        FOfferType.Manufacture |
        FOfferType.Warehouse |
        FOfferType.FreeAppointmentObject |
        FOfferType.PublicCatering |
        FOfferType.AutoService |
        FOfferType.DomesticServices,
    ),
  },
  is_subsidised_mortgage: { availability: isAvailable(FDealType.Sale, FOfferType.FlatOld) },
  is_upcoming_sale: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  jk_flat_type_id: {},
  kids: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  kitchen: {
    availability: isAvailable(FDealType.Any, FOfferType.Urban),
  },
  kitchen_stove: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  kp_id: {},
  land_status: {
    availability: isAvailable(FDealType.Sale, FOfferType.Suburban),
    converter: jsonQuery => {
      const nextOfferType = offerTypeFromJsonQuery(jsonQuery);

      let nextLandStatuses: ELandStatusType[] | null =
        (jsonQuery.land_status &&
          jsonQuery.land_status.value.filter((type: ELandStatusType) => {
            if ((nextOfferType & FOfferType.Land) === 0) {
              return type !== ELandStatusType.InvestmentProject;
            }

            return true;
          })) ||
        null;

      if (nextLandStatuses && nextLandStatuses.length === 0) {
        nextLandStatuses = null;
      }

      return { ...jsonQuery, land_status: terms(nextLandStatuses) };
    },
  },
  lift_service: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  lifts: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  living_area: {
    availability: isAvailable(FDealType.Any, FOfferType.Flat | FOfferType.FlatShared),
  },
  loggia: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  multi_id: {},
  not_last_floor: {
    availability: isAvailable(FDealType.Any, FOfferType.Urban),
  },
  object_type: {},
  office_type: {},
  online_booking: { availability: isAvailable(FDealType.RentDaily, FOfferType.Any) },
  only_flat: {
    availability: isAvailable(FDealType.Sale | FDealType.RentLongterm, FOfferType.Flat | FOfferType.FlatShared),
  },
  only_foot: { availability: isAvailable(FDealType.Any, FOfferType.Any) },
  page: {},
  parking_type: {
    availability: isAvailable(
      FDealType.Any,
      (FOfferType.Urban & ~FOfferType.FlatNew) |
        FOfferType.Office |
        FOfferType.Building |
        FOfferType.Garage |
        FOfferType.Business,
    ),
  },
  penthouse: {
    availability: isAvailable(FDealType.Any, FOfferType.Urban),
  },
  pets: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  phone: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  placement_type: { availability: isAvailable(FDealType.Any, FOfferType.TradeArea) },
  is_encumbrance_realty: { availability: isAvailable(FDealType.Sale, FOfferType.Flat) },
  pool: { availability: isAvailable(FDealType.Any, FOfferType.Suburban & ~FOfferType.Land) },
  prepay: { availability: isAvailable(FDealType.RentLongterm, FOfferType.Residential) },
  price: {},
  price_sm: { availability: isAvailable(FDealType.Sale, FOfferType.Urban) },
  price_type: {},
  property_type: { availability: isAvailable(FDealType.Any, FOfferType.Business) },
  publish_period: {},
  quality: {},
  region: {},
  repair: {
    availability: oneOf(
      isAvailable(FDealType.Sale, FOfferType.Urban & ~FOfferType.FlatNew),
      isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.FlatNew & ~FOfferType.Land),
    ),
  },
  room: {
    converter: jsonQuery => {
      const nextOfferType = offerTypeFromJsonQuery(jsonQuery);
      const room = getTermsValue('room')(jsonQuery);

      if (!room || (nextOfferType & FOfferType.Flat) !== 0) {
        return jsonQuery;
      }

      const nextRoom = room.filter(
        t =>
          ![
            ERoomType.FlatOneRoom,
            ERoomType.FlatTwoRooms,
            ERoomType.FlatThreeRooms,
            ERoomType.FlatFourRooms,
            ERoomType.FlatFiveRooms,
            ERoomType.FlatManyRooms,
            ERoomType.FlatFreeLayout,
            ERoomType.Studio,
          ].includes(t),
      );

      return {
        ...jsonQuery,
        room: terms(nextRoom),
      };
    },
  },
  room_area: {
    availability: isAvailable(FDealType.Any, FOfferType.Room | FOfferType.Bed),
  },
  room_type: { availability: isAvailable(FDealType.Sale | FDealType.RentLongterm, FOfferType.Urban) },
  rooms_count: { availability: isAvailable(FDealType.Any, FOfferType.Room | FOfferType.FlatShared | FOfferType.Bed) },
  rooms_for_sale: { availability: isAvailable(FDealType.Any, FOfferType.Room | FOfferType.Bed) },
  saved_search_id: {},
  security: { availability: isAvailable(FDealType.Sale, FOfferType.Suburban & ~FOfferType.Land) },
  shower: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  show_hotels: { availability: isAvailable(FDealType.RentDaily, FOfferType.Hotel) },
  site: { availability: isAvailable(FDealType.Any, FOfferType.Suburban | FOfferType.CommercialLand) },
  sort: {},
  sost_type: {
    availability: isAvailable(FDealType.Sale, FOfferType.Urban),
    converter: jsonQuery => {
      const nextOfferType = offerTypeFromJsonQuery(jsonQuery);

      let nextSaleTypes: ESaleType[] | null =
        (jsonQuery.sost_type &&
          jsonQuery.sost_type.value.filter((type: ESaleType) => {
            if (nextOfferType === FOfferType.FlatNew) {
              return [
                ESaleType.F3,
                ESaleType.GSKContract,
                ESaleType.Reassignment,
                ESaleType.InvestmentContract,
                ESaleType.PreliminaryContract,
              ].includes(type);
            } else if (nextOfferType === FOfferType.Flat) {
              return [
                ESaleType.Free,
                ESaleType.Alternative,
                ESaleType.F3,
                ESaleType.GSKContract,
                ESaleType.Reassignment,
                ESaleType.InvestmentContract,
                ESaleType.PreliminaryContract,
              ].includes(type);
            } else {
              return ![
                ESaleType.F3,
                ESaleType.GSKContract,
                ESaleType.Reassignment,
                ESaleType.InvestmentContract,
                ESaleType.PreliminaryContract,
              ].includes(type);
            }
          })) ||
        null;

      if (nextSaleTypes && nextSaleTypes.length === 0) {
        nextSaleTypes = null;
      }

      return { ...jsonQuery, sost_type: terms(nextSaleTypes) };
    },
  },
  specialty_types: {
    availability: isAvailable(
      FDealType.Any,
      FOfferType.TradeArea |
        FOfferType.FreeAppointmentObject |
        FOfferType.PublicCatering |
        FOfferType.AutoService |
        FOfferType.DomesticServices |
        FOfferType.Business |
        FOfferType.Office |
        FOfferType.Manufacture |
        FOfferType.Warehouse,
    ),
  },
  star_rating: { availability: isAvailable(FDealType.RentDaily, FOfferType.Hotel) },
  status: {},
  suburban_offer_filter: {},
  total_area: {
    availability: isAvailable(FDealType.Any, FOfferType.Any & ~FOfferType.Land & ~FOfferType.CommercialLand),
  },
  tour_3d: { availability: isAvailable(FDealType.Sale, FOfferType.Any) },
  tv: { availability: isAvailable(FDealType.Rent, FOfferType.Residential & ~FOfferType.Land) },
  wc: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  wc_site: { availability: isAvailable(FDealType.Any, FOfferType.Suburban & ~FOfferType.Land) },
  wc_type: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  windows_type: { availability: isAvailable(FDealType.Any, FOfferType.Urban & ~FOfferType.FlatNew) },
  with_layout: {},
  with_neighbors: {},
  with_newobject: {},
  workplace_count: { availability: isAvailable(FDealType.RentLongterm, FOfferType.Coworking) },
  wp: {},
  year: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  yeargte: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  zalog: { availability: isAvailable(FDealType.RentLongterm, FOfferType.Residential) },
  ready_business_types: { availability: isAvailable(FDealType.Any, FOfferType.Commercial) },
  decorations_list: { availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew) },
  village_mortgage_allowed: { availability: isAvailable(FDealType.Any, FOfferType.Suburban) },
  electronic_trading: {
    availability: oneOf(
      isAvailable(FDealType.Sale, FOfferType.Any),
      isAvailable(FDealType.RentLongterm, FOfferType.Garage),
    ),
  },
  offer_seller_type: { availability: isAvailable(FDealType.Sale, FOfferType.Suburban | FOfferType.Urban) },
  builder: {},
  promo_search_types: {
    availability: isAvailable(FDealType.Sale, FOfferType.FlatNew),
    converter: jsonQuery => {
      const apartments = getApartments(jsonQuery);
      const promoSearchTypes = getTermsValue('promo_search_types')(jsonQuery);
      const allowedPromoSearchTypes = getAllowedPromoSearchTypes(apartments, promoSearchTypes);

      return { ...jsonQuery, promo_search_types: terms(allowedPromoSearchTypes) };
    },
  },
};

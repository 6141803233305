import * as React from 'react';

import { focusScopesStack, removeLinks } from './focusScopesStack';
import { focus, focusFirst, getTabbableCandidates, getTabbableEdges } from './utils';
import { useCallbackRef } from '../../../react-utils-extra/hooks/useCallbackRef';
import { useComposedRefs } from '../../../react-utils-extra/hooks/useComposedRefs';
import { DomPrimitive } from '../../primitives/DomPrimitive';

const AUTOFOCUS_ON_MOUNT = 'focusScope.autoFocusOnMount';
const AUTOFOCUS_ON_UNMOUNT = 'focusScope.autoFocusOnUnmount';
const EVENT_OPTIONS = { bubbles: false, cancelable: true };

const FOCUS_SCOPE_NAME = 'FocusScope';

type FocusScopeElement = React.ElementRef<typeof DomPrimitive.div>;
type PrimitiveDivProps = React.ComponentPropsWithoutRef<typeof DomPrimitive.div>;

interface IFocusScopeProps extends PrimitiveDivProps {
  /**
   * Когда `true`, переход по табуляции от последнего элемента будет фокусировать первый табулируемый элемент,
   * а shift+tab от первого элемента будет фокусировать последний табулируемый элемент.
   * @defaultValue false
   */
  loop?: boolean;

  /**
   * Когда `true`, фокус не может выйти за пределы области фокуса с помощью клавиатуры,
   * указателя или программного фокуса.
   * @defaultValue false
   */
  trapped?: boolean;

  /**
   * Обработчик события, вызываемый при автоматическом фокусировании при монтировании.
   * Может быть предотвращено.
   */
  onMountAutoFocus?: (event: Event) => void;

  /**
   * Обработчик события, вызываемый при автоматическом фокусировании при размонтировании.
   * Может быть предотвращено.
   */
  onUnmountAutoFocus?: (event: Event) => void;
}

const FocusScope = React.forwardRef<FocusScopeElement, IFocusScopeProps>((props, forwardedRef) => {
  const {
    loop = false,
    trapped = false,
    onMountAutoFocus: onMountAutoFocusProp,
    onUnmountAutoFocus: onUnmountAutoFocusProp,
    ...scopeProps
  } = props;
  const [container, setContainer] = React.useState<HTMLElement | null>(null);
  const onMountAutoFocus = useCallbackRef(onMountAutoFocusProp);
  const onUnmountAutoFocus = useCallbackRef(onUnmountAutoFocusProp);
  const lastFocusedElementRef = React.useRef<HTMLElement | null>(null);
  const composedRefs = useComposedRefs(forwardedRef, node => setContainer(node));

  const focusScope = React.useRef({
    paused: false,
    pause() {
      this.paused = true;
    },
    resume() {
      this.paused = false;
    },
  }).current;

  // Обеспечивает захват фокуса, если фокус перемещается программно, например
  React.useEffect(() => {
    // eslint-disable-next-line no-inner-declarations
    function handleFocusIn(event: FocusEvent) {
      if (focusScope.paused || !container) {
        return;
      }
      const target = event.target as HTMLElement | null;
      if (container.contains(target)) {
        lastFocusedElementRef.current = target;
      } else {
        focus(lastFocusedElementRef.current, { select: true });
      }
    }

    // eslint-disable-next-line no-inner-declarations
    function handleFocusOut(event: FocusEvent) {
      if (focusScope.paused || !container) {
        return;
      }
      const relatedTarget = event.relatedTarget as HTMLElement | null;

      // Событие `focusout` с `null` в `relatedTarget` произойдет как минимум в двух случаях:
      //
      // 1. Когда пользователь переключает приложение/вкладки/окна/сам браузер теряет фокус.
      // 2. В Google Chrome, когда фокусированный элемент удаляется из DOM.
      //
      // Мы позволяем браузеру делать свое дело здесь, потому что:
      //
      // 1. Браузер уже запоминает, что было в фокусе, когда страница снова получает фокус.
      // 2. В Google Chrome, если мы попытаемся сфокусироваться на удаленном элементе (как указано ниже), это
      //    приводит к загрузке ЦП на 100%, поэтому мы избегаем этого по этой причине.
      if (relatedTarget === null) {
        return;
      }

      // Если фокус переместился на фактический допустимый элемент (`relatedTarget !== null`)
      // который находится за пределами контейнера, мы перемещаем фокус на последний допустимый фокусированный элемент внутри.
      if (!container.contains(relatedTarget)) {
        focus(lastFocusedElementRef.current, { select: true });
      }
    }

    // Когда фокусированный элемент удаляется из DOM, браузеры перемещают фокус
    // обратно на document.body. В этом случае мы перемещаем фокус на контейнер
    // чтобы правильно захватить фокус.
    // eslint-disable-next-line no-inner-declarations
    function handleMutations(mutations: MutationRecord[]) {
      const focusedElement = document.activeElement as HTMLElement | null;
      if (focusedElement !== document.body) {
        return;
      }
      for (const mutation of mutations) {
        if (mutation.removedNodes.length > 0) {
          focus(container);
        }
      }
    }

    let mutationObserver: MutationObserver | undefined;

    if (trapped) {
      document.addEventListener('focusin', handleFocusIn);
      document.addEventListener('focusout', handleFocusOut);
      const mutationObserver = new MutationObserver(handleMutations);
      if (container) {
        mutationObserver.observe(container, { childList: true, subtree: true });
      }
    }

    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('focusout', handleFocusOut);
      mutationObserver?.disconnect();
    };
  }, [trapped, container, focusScope.paused]);

  React.useEffect(() => {
    if (container) {
      focusScopesStack.add(focusScope);
      const previouslyFocusedElement = document.activeElement as HTMLElement | null;
      const hasFocusedCandidate = container.contains(previouslyFocusedElement);

      if (!hasFocusedCandidate) {
        const mountEvent = new CustomEvent(AUTOFOCUS_ON_MOUNT, EVENT_OPTIONS);
        container.addEventListener(AUTOFOCUS_ON_MOUNT, onMountAutoFocus);
        container.dispatchEvent(mountEvent);
        if (!mountEvent.defaultPrevented) {
          focusFirst(removeLinks(getTabbableCandidates(container)), { select: true });
          if (document.activeElement === previouslyFocusedElement) {
            focus(container);
          }
        }
      }

      return () => {
        container.removeEventListener(AUTOFOCUS_ON_MOUNT, onMountAutoFocus);

        // Мы столкнулись с ошибкой React (исправлено в v17) с фокусировкой при размонтировании.
        // Нам нужно немного задержать фокус, чтобы обойти это на данный момент.
        // См.: https://github.com/facebook/react/issues/17894
        setTimeout(() => {
          const unmountEvent = new CustomEvent(AUTOFOCUS_ON_UNMOUNT, EVENT_OPTIONS);
          container.addEventListener(AUTOFOCUS_ON_UNMOUNT, onUnmountAutoFocus);
          container.dispatchEvent(unmountEvent);
          if (!unmountEvent.defaultPrevented) {
            focus(previouslyFocusedElement ?? document.body, { select: true });
          }
          // нам нужно удалить слушатель после `dispatchEvent`
          container.removeEventListener(AUTOFOCUS_ON_UNMOUNT, onUnmountAutoFocus);

          focusScopesStack.remove(focusScope);
        }, 0);
      };
    }

    return;
  }, [container, onMountAutoFocus, onUnmountAutoFocus, focusScope]);

  // Обеспечивает зацикливание фокуса (при переходе по табуляции на краях)
  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (!loop && !trapped) {
        return;
      }
      if (focusScope.paused) {
        return;
      }

      const isTabKey = event.key === 'Tab' && !event.altKey && !event.ctrlKey && !event.metaKey;
      const focusedElement = document.activeElement as HTMLElement | null;

      if (isTabKey && focusedElement) {
        const container = event.currentTarget as HTMLElement;
        const [first, last] = getTabbableEdges(container);
        const hasTabbableElementsInside = first && last;

        // мы можем зациклить фокус только если у нас есть табулируемые края
        if (!hasTabbableElementsInside) {
          if (focusedElement === container) {
            event.preventDefault();
          }
        } else {
          if (!event.shiftKey && focusedElement === last) {
            event.preventDefault();
            if (loop) {
              focus(first, { select: true });
            }
          } else if (event.shiftKey && focusedElement === first) {
            event.preventDefault();
            if (loop) {
              focus(last, { select: true });
            }
          }
        }
      }
    },
    [loop, trapped, focusScope.paused],
  );

  return <DomPrimitive.div tabIndex={-1} {...scopeProps} ref={composedRefs} onKeyDown={handleKeyDown} />;
});

FocusScope.displayName = FOCUS_SCOPE_NAME;

export { FocusScope };

export type { IFocusScopeProps as FocusScopeProps };

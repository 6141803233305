import { DIALOG_NAME, PORTAL_NAME } from './constants';
import { DialogContextValue, PortalContextValue } from './types';
import { createContextScope } from '../../../react-utils-extra/utils/scopedContext';

export const [createDialogContext, createDialogScope] = createContextScope(DIALOG_NAME);

export const [DialogProvider, useDialogContext] = createDialogContext<DialogContextValue>(DIALOG_NAME);

export const [PortalProvider, usePortalContext] = createDialogContext<PortalContextValue>(PORTAL_NAME, {
  forceMount: undefined,
});

import * as React from 'react';

import { CONTEXT_UPDATE } from './constants';
import { FocusOutsideEvent, PointerDownOutsideEvent } from './types';
import { dispatchUpdate, useFocusOutside } from './useFocusOutside';
import { usePointerDownOutside } from './usePointerDownOutside';
import { useComposedRefs } from '../../../react-utils-extra/hooks/useComposedRefs';
import { useEscapeKeydown } from '../../../react-utils-extra/hooks/useEscapeKeyodwn';
import { composeEventHandlers } from '../../../react-utils-extra/utils/composeEventHandlers';
import { DomPrimitive } from '../../primitives/DomPrimitive';

const DISMISSABLE_LAYER_NAME = 'DismissableLayer';

let originalBodyPointerEvents: string;

const DismissableLayerContext = React.createContext({
  layers: new Set<DismissableLayerElement>(),
  layersWithOutsidePointerEventsDisabled: new Set<DismissableLayerElement>(),
  branches: new Set<DismissableLayerBranchElement>(),
});

type DismissableLayerElement = React.ElementRef<typeof DomPrimitive.div>;
type PrimitiveDivProps = React.ComponentPropsWithoutRef<typeof DomPrimitive.div>;

interface IDismissableLayerProps extends PrimitiveDivProps {
  /**
   * Когда `true`, взаимодействия наведения/фокуса/клика будут отключены на элементах вне
   * `DismissableLayer`. Пользователям нужно будет дважды кликнуть на внешние элементы, чтобы
   * взаимодействовать с ними: один раз, чтобы закрыть `DismissableLayer`, и еще раз, чтобы активировать элемент.
   */
  disableOutsidePointerEvents?: boolean;
  /**
   * Обработчик события, вызываемый при нажатии клавиши Escape.
   * Может быть предотвращено.
   */
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
  /**
   * Обработчик события, вызываемый при событии `pointerdown` вне `DismissableLayer`.
   * Может быть предотвращено.
   */
  onPointerDownOutside?: (event: PointerDownOutsideEvent) => void;
  /**
   * Обработчик события, вызываемый при перемещении фокуса вне `DismissableLayer`.
   * Может быть предотвращено.
   */
  onFocusOutside?: (event: FocusOutsideEvent) => void;
  /**
   * Обработчик события, вызываемый при взаимодействии вне `DismissableLayer`.
   * В частности, когда происходит событие `pointerdown` вне или фокус перемещается вне его.
   * Может быть предотвращено.
   */
  onInteractOutside?: (event: PointerDownOutsideEvent | FocusOutsideEvent) => void;
  /**
   * Обработчик, вызываемый, когда `DismissableLayer` должен быть закрыт
   */
  onDismiss?: () => void;
}

const DismissableLayer = React.forwardRef<DismissableLayerElement, IDismissableLayerProps>((props, forwardedRef) => {
  const {
    disableOutsidePointerEvents = false,
    onEscapeKeyDown,
    onPointerDownOutside,
    onFocusOutside,
    onInteractOutside,
    onDismiss,
    ...layerProps
  } = props;
  const context = React.useContext(DismissableLayerContext);
  const [node, setNode] = React.useState<DismissableLayerElement | null>(null);
  const ownerDocument = node?.ownerDocument ?? globalThis?.document;
  const [, force] = React.useState({});
  const composedRefs = useComposedRefs(forwardedRef, node => setNode(node));
  const layers = Array.from(context.layers);
  const [highestLayerWithOutsidePointerEventsDisabled] = [...context.layersWithOutsidePointerEventsDisabled].slice(-1); // prettier-ignore
  const highestLayerWithOutsidePointerEventsDisabledIndex = layers.indexOf(highestLayerWithOutsidePointerEventsDisabled); // prettier-ignore
  const index = node ? layers.indexOf(node) : -1;
  const isBodyPointerEventsDisabled = context.layersWithOutsidePointerEventsDisabled.size > 0;
  const isPointerEventsEnabled = index >= highestLayerWithOutsidePointerEventsDisabledIndex;

  const pointerDownOutside = usePointerDownOutside(event => {
    const target = event.target as HTMLElement;
    const isPointerDownOnBranch = [...context.branches].some(branch => branch.contains(target));
    if (!isPointerEventsEnabled || isPointerDownOnBranch) {
      return;
    }
    onPointerDownOutside?.(event);
    onInteractOutside?.(event);
    if (!event.defaultPrevented) {
      onDismiss?.();
    }
  }, ownerDocument);

  const focusOutside = useFocusOutside(event => {
    const target = event.target as HTMLElement;
    const isFocusInBranch = [...context.branches].some(branch => branch.contains(target));

    if (isFocusInBranch) {
      return;
    }

    onFocusOutside?.(event);
    onInteractOutside?.(event);

    if (!event.defaultPrevented) {
      onDismiss?.();
    }
  }, ownerDocument);

  useEscapeKeydown(event => {
    const isHighestLayer = index === context.layers.size - 1;
    if (!isHighestLayer) {
      return;
    }
    onEscapeKeyDown?.(event);
    if (!event.defaultPrevented && onDismiss) {
      event.preventDefault();
      onDismiss();
    }
  }, ownerDocument);

  React.useEffect(() => {
    if (!node) {
      return;
    }

    if (disableOutsidePointerEvents) {
      if (context.layersWithOutsidePointerEventsDisabled.size === 0) {
        originalBodyPointerEvents = ownerDocument.body.style.pointerEvents;
        ownerDocument.body.style.pointerEvents = 'none';
      }
      context.layersWithOutsidePointerEventsDisabled.add(node);
    }

    context.layers.add(node);
    dispatchUpdate();

    return () => {
      if (disableOutsidePointerEvents && context.layersWithOutsidePointerEventsDisabled.size === 1) {
        ownerDocument.body.style.pointerEvents = originalBodyPointerEvents;
      }
    };
  }, [node, ownerDocument, disableOutsidePointerEvents, context]);

  /**
   * Мы намеренно предотвращаем объединение этого эффекта с эффектом `disableOutsidePointerEvents`
   * потому что изменение `disableOutsidePointerEvents` удалило бы этот слой из стека
   * и добавило его снова в конец, так что порядок слоев не был бы _порядком создания_.
   * Мы хотим, чтобы они удалялись из контекстных стеков только при размонтировании.
   */
  React.useEffect(() => {
    return () => {
      if (!node) {
        return;
      }
      context.layers.delete(node);
      context.layersWithOutsidePointerEventsDisabled.delete(node);
      dispatchUpdate();
    };
  }, [node, context]);

  React.useEffect(() => {
    const handleUpdate = () => force({});
    document.addEventListener(CONTEXT_UPDATE, handleUpdate);

    return () => document.removeEventListener(CONTEXT_UPDATE, handleUpdate);
  }, []);

  return (
    <DomPrimitive.div
      {...layerProps}
      ref={composedRefs}
      style={{
        pointerEvents: isBodyPointerEventsDisabled ? (isPointerEventsEnabled ? 'auto' : 'none') : undefined,
        ...props.style,
      }}
      onFocusCapture={composeEventHandlers(props.onFocusCapture, focusOutside.onFocusCapture)}
      onBlurCapture={composeEventHandlers(props.onBlurCapture, focusOutside.onBlurCapture)}
      onPointerDownCapture={composeEventHandlers(props.onPointerDownCapture, pointerDownOutside.onPointerDownCapture)}
    />
  );
});

DismissableLayer.displayName = DISMISSABLE_LAYER_NAME;

/* -------------------------------------------------------------------------------------------------
 * DismissableLayerBranch
 * -----------------------------------------------------------------------------------------------*/

const BRANCH_NAME = 'DismissableLayerBranch';

type DismissableLayerBranchElement = React.ElementRef<typeof DomPrimitive.div>;
interface IDismissableLayerBranchProps extends PrimitiveDivProps {}

const DismissableLayerBranch = React.forwardRef<DismissableLayerBranchElement, IDismissableLayerBranchProps>(
  (props, forwardedRef) => {
    const context = React.useContext(DismissableLayerContext);
    const ref = React.useRef<DismissableLayerBranchElement>(null);
    const composedRefs = useComposedRefs(forwardedRef, ref);

    React.useEffect(() => {
      const node = ref.current;
      if (node) {
        context.branches.add(node);

        return () => {
          context.branches.delete(node);
        };
      }

      return () => {};
    }, [context.branches]);

    return <DomPrimitive.div {...props} ref={composedRefs} />;
  },
);

DismissableLayerBranch.displayName = BRANCH_NAME;

export { DismissableLayer, DismissableLayerBranch };
export type { IDismissableLayerProps as DismissableLayerProps };

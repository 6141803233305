import { useCallback, useEffect, useState } from 'react';

import { useOfferContext } from '../../../../../context';
import { getChatOnboardingCookie, setChatOnboardingCookie } from '../../../../../utils/chatOnboarding';
import { TChatOnboarding } from 'shared/types/chatOnboarding';

interface IOnboardingTooltipResult {
  open: boolean;
  closeHandler: () => void;
}

const ONBOARDING_COOKIE_NAME_MAP: Record<keyof TChatOnboarding, string> = {
  offerChatOnboarding: 'frontend-serp.offer_chat_onboarding_shown',
  consultantChatOnboarding: 'frontend-serp.consultant_chat_onboarding_shown',
};

export const useChatOnboarding = (onboardingType: keyof TChatOnboarding): IOnboardingTooltipResult => {
  const { offer, chatOnboarding } = useOfferContext();
  const [open, setOpen] = useState(false);

  const cookieName = ONBOARDING_COOKIE_NAME_MAP[onboardingType];

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const canShowForOffer = chatOnboarding[onboardingType]?.onboardingOfferId === offer.id;
  const chatWasOpened = chatOnboarding[onboardingType]?.wasChatOpened;

  useEffect(() => {
    if (canShowForOffer && !getChatOnboardingCookie(cookieName)) {
      setOpen(true);
      setChatOnboardingCookie(cookieName);
    }
  }, [canShowForOffer, cookieName]);

  useEffect(() => {
    if (open && chatWasOpened) {
      closeHandler();
    }
  }, [open, chatWasOpened, closeHandler]);

  return {
    open,
    closeHandler,
  };
};

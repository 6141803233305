import * as React from 'react';

import { DESCRIPTION_NAME } from './constants';
import { useDialogContext } from './context';
import { ScopedProps } from './types';
import { DomPrimitive } from '../../primitives';

type TDialogDescriptionElement = React.ElementRef<typeof DomPrimitive.p>;
type TPrimitiveParagraphProps = React.ComponentPropsWithoutRef<typeof DomPrimitive.p>;
type TDialogDescriptionProps = TPrimitiveParagraphProps & {};

const DialogDescription = React.forwardRef<TDialogDescriptionElement, TDialogDescriptionProps>(
  (props: ScopedProps<TDialogDescriptionProps>, forwardedRef) => {
    const { __scopeDialog, ...descriptionProps } = props;
    const context = useDialogContext(DESCRIPTION_NAME, __scopeDialog);

    return <DomPrimitive.p id={context.descriptionId} {...descriptionProps} ref={forwardedRef} />;
  },
);

DialogDescription.displayName = DESCRIPTION_NAME;

export { DialogDescription as Description };
export type { TDialogDescriptionProps, TDialogDescriptionElement };

import * as React from 'react';

import { TRIGGER_NAME } from './constants';
import { useDialogContext } from './context';
import { ScopedProps } from './types';
import { getDataStateValue } from './utils';
import { useComposedRefs } from '../../../react-utils-extra/hooks/useComposedRefs';
import { composeEventHandlers } from '../../../react-utils-extra/utils/composeEventHandlers';
import { DomPrimitive } from '../../primitives';

type TDialogTriggerElement = React.ElementRef<typeof DomPrimitive.button>;
type TPrimitiveButtonProps = React.ComponentPropsWithoutRef<typeof DomPrimitive.button>;
type TDialogTriggerProps = TPrimitiveButtonProps & {};

export const DialogTrigger = React.forwardRef<TDialogTriggerElement, TDialogTriggerProps>(
  (props: ScopedProps<TDialogTriggerProps>, forwardedRef) => {
    const { __scopeDialog, ...triggerProps } = props;
    const context = useDialogContext(TRIGGER_NAME, __scopeDialog);
    const composedTriggerRef = useComposedRefs(forwardedRef, context.triggerRef);

    return (
      <DomPrimitive.button
        type="button"
        aria-haspopup="dialog"
        aria-expanded={context.open}
        aria-controls={context.contentId}
        data-state={getDataStateValue(context.open)}
        {...triggerProps}
        ref={composedTriggerRef}
        onClick={composeEventHandlers(props.onClick, context.onOpenToggle)}
      />
    );
  },
);

DialogTrigger.displayName = TRIGGER_NAME;

export { DialogTrigger as Trigger };
export type { TDialogTriggerProps as IDialogTriggerProps, TDialogTriggerElement };

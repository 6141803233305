import { IconClose16 } from '@cian/ui-kit';
import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './CookiesAgreementModal.css';
import { CookiesAgreementModalContentLoadable } from './components/CookiesAgreementModalContent';
import * as Dialog from '../../../packages/ui-kit-extra/dialog';
import { LoadableErrorBoundary } from '../LoadableErrorBoundary';

interface ICookiesAgreementModalProps {
  open?: boolean;
  phone?: boolean;
  onAccept(): void;
  onOpenChange(open: boolean): void;
}

export const CookiesAgreementModal: React.FC<ICookiesAgreementModalProps> = ({
  open,
  phone,
  onAccept,
  onOpenChange,
}) => {
  const handleRetryClick = React.useCallback(() => {
    CookiesAgreementModalContentLoadable.load();
  }, []);

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Content size="L">
        <Dialog.Close theme="fill_dark" size="XS" icon={<IconClose16 color="current_color" />} title="Закрыть" />

        <Dialog.Header>
          <Dialog.Title>Соглашение об использовании cookie-файлов</Dialog.Title>
        </Dialog.Header>

        <Dialog.Body>
          <div className={styles['content']}>
            <LoadableErrorBoundary onRetry={handleRetryClick}>
              <CookiesAgreementModalContentLoadable />
            </LoadableErrorBoundary>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.FooterActionButtons>
            <Button
              onClick={() => onOpenChange(false)}
              theme={phone ? 'fill_secondary' : 'fill_white_primary'}
              fullWidth={phone}
            >
              Отменить
            </Button>
            <Button onClick={onAccept} theme="fill_primary" fullWidth={phone}>
              Принять
            </Button>
          </Dialog.FooterActionButtons>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

import { UIText2 } from '@cian/ui-kit';
import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './BuilderChatOnboardingContent.css';

interface IBuilderChatOnboardingContentProps {
  wide: boolean;
}

export const BuilderChatOnboardingContent: React.FC<IBuilderChatOnboardingContentProps> = ({ wide }) => {
  return (
    <div className={clsx(styles['content'], wide && styles['wide'])}>
      <UIText2 color="text-inverted-default" whiteSpace="pre-line">
        Теперь можно написать застройщику в чате
      </UIText2>
    </div>
  );
};

import {
  ArticleHeading1,
  ArticleHeading2,
  ArticleParagraph1,
  Image,
  ITypographyComponentProps,
  RoundButton,
  useDeviceType,
} from '@cian/ui-kit';
import { IRoundButtonProps } from '@cian/ui-kit/button/types';
import { IImageProps } from '@cian/ui-kit/image/types';
import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './Dialog.css';
import * as DialogPrimitive from './primitives';
import { DomPrimitive } from '../primitives';

const DialogTrigger = DialogPrimitive.Trigger;

type TDialogCloseProps = IRoundButtonProps & { sticky?: boolean };

export const DialogClose = React.forwardRef<HTMLButtonElement, TDialogCloseProps>(
  ({ sticky = true, ...props }, ref) => {
    return (
      <DomPrimitive.div className={clsx(styles['close'], sticky && styles['close-sticky'])}>
        <DomPrimitive.div className={styles['close-inner']}>
          <DialogPrimitive.Close asChild>
            <RoundButton {...props} ref={ref} />
          </DialogPrimitive.Close>
        </DomPrimitive.div>
      </DomPrimitive.div>
    );
  },
);

DialogClose.displayName = `${DialogPrimitive.Close.displayName}.Styled`;

type TDialogRootProps = Omit<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root>, 'modal'> & {};
const DialogRoot: React.FC<TDialogRootProps> = props => <DialogPrimitive.Root {...props} modal={true} />;
DialogRoot.displayName = `${DialogPrimitive.Root.displayName}.Styled`;

type DialogContentElement = React.ElementRef<typeof DialogPrimitive.Content>;

type TDialogContentProps = Omit<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>, 'asChild'> &
  Pick<React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>, 'forceMount'> & {
    overlay?: 'transparent' | 'blured';
    size?: 'L' | 'M' | 'S';
    portalContainer?: HTMLElement;
  };

const DialogContent = React.forwardRef<DialogContentElement, React.PropsWithChildren<TDialogContentProps>>(
  ({ overlay, className, portalContainer, size = 'M', forceMount, children, ...props }, forwardedRef) => {
    return (
      <DialogPrimitive.Portal container={portalContainer} forceMount={forceMount}>
        <DialogPrimitive.Overlay
          className={clsx(
            styles['overlay'],
            overlay === 'transparent' && styles['overlay-transparent'],
            overlay === 'blured' && styles['overlay-blured'],
          )}
        >
          <div className={styles['content-wrapper']}>
            <div className={styles['content-inner']}>
              <DialogPrimitive.Content
                {...props}
                ref={forwardedRef}
                className={clsx(styles['content'], styles[`content-${size.toLocaleLowerCase()}`], className)}
              >
                {children}
              </DialogPrimitive.Content>
            </div>
          </div>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    );
  },
);
DialogContent.displayName = `${DialogPrimitive.Content.displayName}.Styled`;

type DialogTitleElement = React.ElementRef<typeof ArticleHeading1> | React.ElementRef<typeof ArticleHeading2>;
type DialogTitleProps = ITypographyComponentProps;
const DialogTitle = React.forwardRef<DialogTitleElement, DialogTitleProps>((props, forwardedRef) => {
  const deviceType = useDeviceType();
  const Heading = React.useMemo(() => {
    switch (deviceType) {
      case 'phone':
        return ArticleHeading2;

      default:
        return ArticleHeading1;
    }
  }, [deviceType]);

  return (
    <DomPrimitive.div className={styles['title']}>
      <DialogPrimitive.Title asChild>
        <Heading {...props} ref={forwardedRef} />
      </DialogPrimitive.Title>
    </DomPrimitive.div>
  );
});
DialogTitle.displayName = `${DialogPrimitive.Title.displayName}.Styled`;

type DialogDescriptionElement = React.ElementRef<typeof ArticleParagraph1>;
type DialogDescriptionProps = ITypographyComponentProps;
const DialogDescription = React.forwardRef<DialogDescriptionElement, DialogDescriptionProps>((props, forwardedRef) => (
  <DomPrimitive.div className={styles['description']}>
    <DialogPrimitive.Description asChild>
      <ArticleParagraph1 {...props} ref={forwardedRef} />
    </DialogPrimitive.Description>
  </DomPrimitive.div>
));
DialogDescription.displayName = `${DialogPrimitive.Description.displayName}.Styled`;

const DialogHeader: React.FC<React.PropsWithChildren<{ stycky?: boolean }>> = ({ children, stycky = true }) => (
  <DomPrimitive.div className={clsx(styles['header'], stycky && styles['header-sticky'])}>{children}</DomPrimitive.div>
);

type TDialogHeaderImageProps = Omit<IImageProps, 'borderRadius' | 'height' | 'width'>;
type TDialogHeaderImageElement = React.ElementRef<typeof Image>;

export const DialogHeaderImage = React.forwardRef<TDialogHeaderImageElement, TDialogHeaderImageProps>(
  ({ ...imageProps }, ref) => (
    <DomPrimitive.div className={styles['header-image']}>
      <Image {...imageProps} borderRadius={0} ref={ref} />
    </DomPrimitive.div>
  ),
);
DialogHeaderImage.displayName = 'DialogHeaderImage';

const DialogFooter: React.FC<React.PropsWithChildren<{ sticky?: boolean }>> = ({ children, sticky = true }) => (
  <DomPrimitive.div className={clsx(styles['footer'], sticky && styles['footer-sticky'])}>{children}</DomPrimitive.div>
);

const DialogFooterActionButtons: React.FC<React.PropsWithChildren> = ({ children }) => (
  <DomPrimitive.div className={styles['footer-action-buttons']}>{children}</DomPrimitive.div>
);

const DialogBody: React.FC<React.PropsWithChildren> = ({ children }) => (
  <DomPrimitive.div className={styles['body']}>{children}</DomPrimitive.div>
);

export {
  DialogClose as Close,
  DialogContent as Content,
  DialogDescription as Description,
  DialogFooter as Footer,
  DialogFooterActionButtons as FooterActionButtons,
  DialogHeader as Header,
  DialogHeaderImage as HeaderImage,
  DialogBody as Body,
  DialogRoot as Root,
  DialogTitle as Title,
  DialogTrigger as Trigger,
};

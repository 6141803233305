import { Checkbox } from '@cian/ui-kit';
import * as React from 'react';

import { getSaleTypesList } from './helpers';
import { useSeparateState } from './separateState/useSeparateState';
import { NonEmptyArray, FOfferType } from '../../../../../JsonQuery';
import { ESaleType } from '../../../types/jsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { FilterSection } from '../common/InlineFilterSection';

interface ISaleTypeProps {
  offerType: FOfferType;
  saleType: NonEmptyArray<ESaleType> | null;
  mortgageAvailable: boolean | null;
  onSaleTypeChange(value: NonEmptyArray<ESaleType> | null): void;
  onMortgageAvailableChange(value: boolean | null): void;
}

export const SaleType: React.FC<ISaleTypeProps> = ({
  offerType,
  saleType,
  mortgageAvailable,
  onSaleTypeChange,
  onMortgageAvailableChange,
}) => {
  const saleTypes = getSaleTypesList(offerType);
  const newbuildingsSaleTypes = getSaleTypesList(FOfferType.FlatNew);
  const isMixedList = offerType === FOfferType.Flat;

  const { onOthersChange, onNewbuildingChange, othersList, flatNewList } = useSeparateState({
    onSaleTypeChange,
    saleType,
  });

  return (
    <Filter id="advancedFilter_saleType" label="Условия продажи">
      <InlineFilterGroup>
        <InlineFilter>
          <Checkbox
            label="Возможна ипотека"
            checked={!!mortgageAvailable}
            onChange={event => onMortgageAvailableChange(event.target.checked ? true : null)}
          />
        </InlineFilter>
      </InlineFilterGroup>

      {!isMixedList ? (
        <InlineFilterGroup>
          <FilterSection title={null}>
            <InlineFilter>
              <CheckboxButtonGroupUnselectable options={saleTypes} value={saleType} onChange={onSaleTypeChange} />
            </InlineFilter>
          </FilterSection>
        </InlineFilterGroup>
      ) : (
        <>
          <InlineFilterGroup>
            <FilterSection title={'Во вторичке'}>
              <InlineFilter>
                <CheckboxButtonGroupUnselectable options={saleTypes} value={othersList} onChange={onOthersChange} />
              </InlineFilter>
            </FilterSection>
          </InlineFilterGroup>
          <InlineFilterGroup>
            <FilterSection title="В новостройках">
              <InlineFilter>
                <CheckboxButtonGroupUnselectable
                  options={newbuildingsSaleTypes}
                  value={flatNewList}
                  onChange={onNewbuildingChange}
                />
              </InlineFilter>
            </FilterSection>
          </InlineFilterGroup>
        </>
      )}
    </Filter>
  );
};

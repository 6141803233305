import * as React from 'react';

import { CONTENT_NAME } from './constants';
import { useDialogContext, usePortalContext } from './context';
import { ScopedProps } from './types';
import { getDataStateValue } from './utils';
import { useComposedRefs } from '../../../react-utils-extra/hooks/useComposedRefs';
import { composeEventHandlers } from '../../../react-utils-extra/utils/composeEventHandlers';
import { DismissableLayer } from '../../focus-control/DismissableLayer/DismissableLayer';
import { useFocusGuards } from '../../focus-control/FocusScope/FocusGuards';
import { FocusScope } from '../../focus-control/FocusScope/FocusScope';
import { Presence } from '../../presence/Presence';

type TDismissableLayerProps = React.ComponentPropsWithoutRef<typeof DismissableLayer>;
type TFocusScopeProps = React.ComponentPropsWithoutRef<typeof FocusScope>;
type TDialogContentImplElement = React.ElementRef<typeof DismissableLayer>;

type TDialogContentImplProps = Omit<TDismissableLayerProps, 'onDismiss'> & {
  /**
   * Когда `true`, фокус не может покинуть `Content` с помощью клавиатуры,
   * указателя или программного фокуса.
   * @defaultValue false
   */
  trapFocus?: TFocusScopeProps['trapped'];

  /**
   * Обработчик события, вызываемый при автоматическом фокусировании при открытии.
   * Может быть предотвращено.
   */
  onOpenAutoFocus?: TFocusScopeProps['onMountAutoFocus'];

  /**
   * Обработчик события, вызываемый при автоматическом фокусировании при закрытии.
   * Может быть предотвращено.
   */
  onCloseAutoFocus?: TFocusScopeProps['onUnmountAutoFocus'];
};

const DialogContentImpl = React.forwardRef<TDialogContentImplElement, ScopedProps<TDialogContentImplProps>>(
  (props, forwardedRef) => {
    const { __scopeDialog, trapFocus, onOpenAutoFocus, onCloseAutoFocus, ...contentProps } = props;
    const context = useDialogContext(CONTENT_NAME, __scopeDialog);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const composedRefs = useComposedRefs(forwardedRef, contentRef);

    // Убедитесь, что во всем дереве есть focus-guards, так как наш `Dialog` будет
    // последним элементом в DOM (из-за `Portal`)
    useFocusGuards();

    return (
      <FocusScope
        asChild
        loop
        trapped={trapFocus}
        onMountAutoFocus={onOpenAutoFocus}
        onUnmountAutoFocus={onCloseAutoFocus}
      >
        <DismissableLayer
          role="dialog"
          id={context.contentId}
          aria-describedby={context.descriptionId}
          aria-labelledby={context.titleId}
          data-state={getDataStateValue(context.open)}
          {...contentProps}
          ref={composedRefs}
          onDismiss={() => context.onOpenChange(false)}
        />
      </FocusScope>
    );
  },
);

DialogContentImpl.displayName = `${CONTENT_NAME}.Impl`;

/* -----------------------------------------------------------------------------------------------*/

type TDialogContentTypeElement = TDialogContentImplElement;
type TDialogContentTypeProps = Omit<TDialogContentImplProps, 'trapFocus' | 'disableOutsidePointerEvents'> & {};

const DialogContentModal = React.forwardRef<TDialogContentTypeElement, ScopedProps<TDialogContentTypeProps>>(
  (props, forwardedRef) => {
    const context = useDialogContext(CONTENT_NAME, props.__scopeDialog);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const composedRefs = useComposedRefs(forwardedRef, context.contentRef, contentRef);

    return (
      <DialogContentImpl
        {...props}
        ref={composedRefs}
        // мы убеждаемся, что фокус не захватывается, когда `DialogContent` закрыт
        // (закрыт !== размонтирован при анимации)
        trapFocus={context.open}
        disableOutsidePointerEvents
        onCloseAutoFocus={composeEventHandlers(props.onCloseAutoFocus, event => {
          event.preventDefault();
          context.triggerRef.current?.focus();
        })}
        onPointerDownOutside={composeEventHandlers(props.onPointerDownOutside, event => {
          const originalEvent = event.detail.originalEvent;
          const ctrlLeftClick = originalEvent.button === 0 && originalEvent.ctrlKey === true;
          const isRightClick = originalEvent.button === 2 || ctrlLeftClick;

          // Если событие является правым щелчком, мы не должны закрывать, потому что
          // это фактически как если бы мы щелкнули правой кнопкой мыши по `Overlay`.
          if (isRightClick) {
            event.preventDefault();
          }
        })}
        // Когда фокус захвачен, событие `focusout` все равно может произойти.
        // Мы убеждаемся, что не вызываем наш `onDismiss` в таком случае.
        onFocusOutside={composeEventHandlers(props.onFocusOutside, event => event.preventDefault())}
      />
    );
  },
);

DialogContentModal.displayName = `${CONTENT_NAME}.Modal`;

/* -----------------------------------------------------------------------------------------------*/

const DialogContentNonModal = React.forwardRef<TDialogContentTypeElement, ScopedProps<TDialogContentTypeProps>>(
  (props, forwardedRef) => {
    const context = useDialogContext(CONTENT_NAME, props.__scopeDialog);
    const hasInteractedOutsideRef = React.useRef(false);
    const hasPointerDownOutsideRef = React.useRef(false);

    return (
      <DialogContentImpl
        {...props}
        ref={forwardedRef}
        trapFocus={false}
        disableOutsidePointerEvents={false}
        onCloseAutoFocus={event => {
          props.onCloseAutoFocus?.(event);

          if (!event.defaultPrevented) {
            if (!hasInteractedOutsideRef.current) {
              context.triggerRef.current?.focus();
            }
            // Всегда предотвращаем автоматический фокус, потому что мы либо фокусируем вручную, либо хотим фокус пользователя
            event.preventDefault();
          }

          hasInteractedOutsideRef.current = false;
          hasPointerDownOutsideRef.current = false;
        }}
        onInteractOutside={event => {
          props.onInteractOutside?.(event);

          if (!event.defaultPrevented) {
            hasInteractedOutsideRef.current = true;
            if (event.detail.originalEvent.type === 'pointerdown') {
              hasPointerDownOutsideRef.current = true;
            }
          }

          // Предотвращаем закрытие при нажатии на триггер.
          // Поскольку триггер уже настроен на закрытие, без этого он закроется и сразу же откроется.
          const target = event.target as HTMLElement;
          const targetIsTrigger = context.triggerRef.current?.contains(target);
          if (targetIsTrigger) {
            event.preventDefault();
          }

          // В Safari, если триггер находится внутри контейнера с tabIndex={0}, при нажатии
          // мы получим событие pointer down outside на триггере, но затем последующее
          // событие focus outside на контейнере, мы игнорируем любое событие focus outside, когда у нас уже было событие pointer down outside.
          if (event.detail.originalEvent.type === 'focusin' && hasPointerDownOutsideRef.current) {
            event.preventDefault();
          }
        }}
      />
    );
  },
);

DialogContentNonModal.displayName = `${CONTENT_NAME}.NonModal`;

/* -----------------------------------------------------------------------------------------------*/

type TDialogContentElement = TDialogContentTypeElement;
type TDialogContentProps = TDialogContentTypeProps & {
  /**
   * Используется для принудительного монтирования, когда требуется больший контроль. Полезно при
   * управлении анимацией с помощью библиотек анимации React.
   */
  forceMount?: true;
};

export const DialogContent = React.forwardRef<TDialogContentElement, ScopedProps<TDialogContentProps>>(
  (props, forwardedRef) => {
    const portalContext = usePortalContext(CONTENT_NAME, props.__scopeDialog);
    const { forceMount = portalContext.forceMount, ...contentProps } = props;
    const context = useDialogContext(CONTENT_NAME, props.__scopeDialog);

    return (
      <Presence present={forceMount || context.open}>
        {context.modal ? (
          <DialogContentModal {...contentProps} ref={forwardedRef} />
        ) : (
          <DialogContentNonModal {...contentProps} ref={forwardedRef} />
        )}
      </Presence>
    );
  },
);

DialogContent.displayName = CONTENT_NAME;

export { DialogContent as Content };
export type { TDialogContentElement, TDialogContentProps };
